module.exports = {
  Lang: {
    DefaultPath: "it",
    AvailablePaths: [
      "it",
      "en",
    ],
    PathByLocale: {
      "it-it": "it",
      "en-us": "en",
    },
    RouteByLocale: {
      artist: {
        "it-it": "artisti",
        "en-us": "artists",
      },
      artwork: {
        "it-it": "collezione",
        "en-us": "collection",
      },
      contact: {
        "it-it": "contatti",
        "en-us": "contacts",
      },
    }
  }
};
